<template>
  <div class="main">
    <div class="back" @click="topath('/index')">分会管理</div>

    <div class="thetitle">IADGE分会简介</div>
    <div class="profile">
      <div class="title"><div class="infor">一般信息</div></div>
      <div class="row">
        <div class="titler">分会名称：</div>
        <div class="pink left20">IADGE粤港奥大湾区联盟(分会)</div>
      </div>
      <div class="row">
        <div class="titler">会员类别：</div>
        <div class="left20">职业</div>
      </div>
      <div class="row">
        <div class="titler">电话：</div>
        <div class="input phone">
          <el-input
            v-model="lastname.phone"
            placeholder="请输入你的电话号码"
          ></el-input>
        </div>
      </div>
      <div class="title theaddress"><div class="infor">地址</div></div>
      <div class="row">
        <div class="nation">
          <div class="titler">国家：</div>
          <div class="input">
            <el-input v-model="lastname.nation" placeholder=""></el-input>
          </div>
        </div>
        <div class="city">
          <div class="titler">城市：</div>
          <div class="input">
            <el-input v-model="lastname.city" placeholder=""></el-input>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="titler">邮编：</div>
        <div class="input">
          <el-input v-model="lastname.postcode" placeholder=""></el-input>
        </div>
      </div>
      <div class="row">
        <div class="titler">街道：</div>
        <div class="input">
          <el-input v-model="lastname.street" placeholder=""></el-input>
        </div>
      </div>
      <div class="row margin40">
        <div class="titler">分会邮箱：</div>
        <div class="input">
          <el-input v-model="lastname.email" placeholder=""></el-input>
          <div class="hint">(注意：请不要使用@iadge.org)</div>
        </div>
      </div>
      <div class="row margin40">
        <div class="titler">分会主页：</div>
        <div class="input">
          <el-input v-model="lastname.Clubhome" placeholder=""></el-input>
          <div class="hint">(注意：请在您的URL中包含http://)</div>
        </div>
      </div>
      <div class="row margin40">
        <div class="titler">分会其他URL地址：</div>
        <div class="input">
          <el-input v-model="lastname.Other_address" placeholder=""></el-input>
          <div class="hint">(注意：请在您的URL中包含http://)</div>
        </div>
      </div>
      <!-- <div class="row">
        <div class="nation">
          <div class="titler">分会会员总数：</div>
          <div class="input">
            <el-input v-model="lastname.membership" placeholder=""></el-input>
          </div>
        </div>
        <div class="city">
          <div class="titler">IADGE干事数：</div>
          <div class="input">
            <el-input v-model="lastname.officers" placeholder=""></el-input>
          </div>
        </div>
      </div> -->
      <div class="row">
        <div class="titler">下次选举日期：</div>
        <div class="input phone">
          <a-date-picker
            size="large"
            v-model:value="lastname.date"
            placeholder="请选择日期"
            @change="getdate"
            :locale="i18nLocale"
          />
        </div>
      </div>
      <div
        class="save hoverbottom"
        @click="submits(lastname)"
        :class="{ click: submit }"
      >
        保存
      </div>
    </div>
  </div>
</template>
    
<style lang="scss">
.phone {
  .ant-picker-large {
    width: 40.5%;
    height: 64px;
    border: 2px solid #777777 !important;
    padding: 20px 20px !important;
    box-sizing: border-box;
    .anticon svg {
      width: 22px !important;
      height: 22px !important;
    }
  }
}

.el-input__wrapper {
  height: 64px;
  box-sizing: border-box;
  padding: 20px 20px !important;
  border: 2px solid #777777 !important;
  border-radius: 8px !important;
}
</style>
<script>
import { home, chapter } from "../api/twice";
import { ElNotification } from "element-plus";
import dayjs from "dayjs";
export default {
  name: "Home",
  data() {
    return {
      info: [],
      i18nLocale: "zh-cn",
      lastname: {
        phone: "",
        postcode: "",
        Clubhome: "",
        email: "",
        street: "",
        Other_address: "",
        nation: "",
        city: "",
        membership: "",
        officers: "",
        date: "",
      },
    };
  },
  methods: {
    getdate(v, model) {
      this.lastname.date = dayjs(model);
      console.log(v, model);
    },
    submits() {
      if (!this.submit) return;
      this.postchapter();
      // console.log(e, 878798);
    },
    topath(e) {
      console.log(e, 12345);
      this.$router.push(e);
    },
    async gethome() {
      const data = await home({});
      this.info = data;
      this.lastname.phone = data.user.tel || "";
      this.lastname.postcode = data.zip_code || "";
      this.lastname.Clubhome = data.chapter_homepage_url || "";
      this.lastname.email = data.client_email || "";
      this.lastname.street = data.address || "";
      this.lastname.Other_address = data.chapter_other_url || "";
      this.lastname.nation = data.countries || "";
      this.lastname.city = data.city || "";
      // this.lastname.membership = data.id || "";
      // this.lastname.officers = data.id || "";
      // info["contract_expire_time"] = dayjs(info["contract_expire_time"])
      console.log(dayjs(data.date_next_election), 98);
      this.lastname.date = dayjs(data.date_next_election) || "";

      // console.log(1, this.info);
    },
    async postchapter() {
      console.log(this.lastname.date, 98765654);
      const data = await chapter({
        tel: this.lastname.phone,
        client_email: this.lastname.email,
        countries: this.lastname.nation,
        city: this.lastname.city,
        zip_code: this.lastname.postcode,
        address: this.lastname.street,
        chapter_homepage_url: this.lastname.Clubhome,
        chapter_other_url: this.lastname.Other_address,
        date_next_election: this.lastname.date,
      });
      // console.log(data, 123456);
      if (data) {
        ElNotification({
          title: "Success",
          message: "IADGE分会简介修改成功!",
          type: "success",
        });
      }
      // console.log(data, 123456);
    },
  },
  computed: {
    submit() {
      if (this.lastname.phone) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.gethome();
  },
  components: {},
};
</script>
    <style scoped lang="scss">
.main {
  padding: 100px 0px;
  max-width: 1264px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .back {
    position: absolute;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 19px;
    top: 20px;
    left: 0px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
      color: #c81677;
    }
    &::after {
      content: "";
      display: inline-block;
      width: 17px; /* 设置图片的宽度 */
      height: 17px; /* 设置图片的高度 */
      background-image: url("https://bunseninstitute.oss-cn-hangzhou.aliyuncs.com/files/images/%E4%B8%89%E8%A7%92%E5%BD%A2.svg"); /* 替换为您的图片 URL */
      background-size: cover; /* 调整图片的显示方式 */
      margin-left: 6px; /* 调整图片与 div 之间的间距 */
      margin-right: 6px;
      transform: rotate(180deg);
    }
  }
  .thetitle {
    margin-bottom: 40px;
  }
  .profile {
    padding: 56px 60px;
    border: 2px solid #333333;
    border-radius: 40px 0 0 0;
    width: 100%;
    box-sizing: border-box;
    color: #333;
    .save {
      width: 364px;
      margin: 0 auto;
      text-align: center;
      height: 58px;
      background: #ccc;
      border-radius: 50px 50px 50px 50px;
      line-height: 58px;
      font-size: 20px;
      font-weight: 400;
      color: #ffffff;
      margin-top: 60px;
      cursor: no-drop;
    }

    .row {
      display: flex;
      margin-bottom: 20px;
      align-items: center;

      .nation {
        display: flex;
        align-items: center;
        flex: 1;
      }
      .city {
        display: flex;
        align-items: center;
        flex: 1;
      }
      //   :nth-child(1) {
      //     font-size: 18px;
      //     font-weight: bold;
      //     color: #333333;
      //     line-height: 21px;
      //     width: 126px;
      //     text-align: right;
      //   }
      .titler {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        line-height: 21px;
        width: 163px;
        text-align: right;
      }
      .left20 {
        margin-left: 20px;
      }
      //   :nth-child(2) {
      //     margin-left: 20px;
      //   }
      .phone {
        //   justify-content: initial !important;
        .el-input {
          width: 40.5%;
        }
      }
      .input {
        margin-left: 20px;
        // margin-bottom: 40px;
        // width: 100%;
        flex: 1;
        position: relative;
        .hint {
          font-size: 12px;
          font-weight: 400;
          color: #333333;
          line-height: 14px;
          margin-top: 4px;
          position: absolute;
          margin-bottom: 20px;
        }
      }
    }
    .margin40 {
      margin-bottom: 40px;
    }
    .theaddress {
      margin-top: 40px;
    }
    .title {
      font-size: 24px;
      font-weight: bold;
      color: #000000;
      line-height: 28px;
      position: relative;
      margin-bottom: 32px;
      .infor {
        display: flex;
        align-items: center;
        &::before {
          content: "";
          width: 6px;
          height: 24px;
          background: #c81677;
          position: absolute;
          left: -10px;
        }
      }
    }
  }
}
</style>
    